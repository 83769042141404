import { useParams } from "react-router-dom";
import ArtBuildingHeader, { ArtDetail, ArtOverview, ArtVirtualTour, GetInTouch } from "../../components/ArtComponents";
import Footer from "../../components/FooterComponents";
import Navbar from "../../components/NavbarComponents";
import { useEffect, useState } from "react";
import { apiHandler, apiImageFilesHandler, apiImageHandler } from "../../utilities/apiHandler";

export default function ArtPage() {
    const { type } = useParams()

    const [headerData, setHeaderData] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [listsData, setListsData] = useState([]);
    const [files, setFiles] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headerData = await apiHandler("art_" + type + "_detail_header");
                headerData.overview_image = await apiImageHandler(headerData.overview_image);
                const files = await apiImageFilesHandler(headerData.header_images)
                setFiles(files)

                const sliderData = await apiHandler("art_" + type + "_sliders");
                sliderData.map(async (item) => {
                    item.image = await apiImageHandler(item.image);
                    return item;
                })

                const listsData = await apiHandler("art_" + type + "_lists");
                listsData.map(async (item) => {
                    item.image = await apiImageHandler(item.image);
                    return item;
                })

                setListsData(listsData)
                setSliderData(sliderData);
                setHeaderData(headerData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    return (
        <div className="flex flex-col justify-center items-center overflow-hidden max-w-[100vw] ">
            <Navbar type="default" />
            <ArtBuildingHeader data={headerData} files={files}/>
            <ArtOverview data={headerData} />
            <ArtDetail data={sliderData} />
            <ArtVirtualTour data={listsData} />
            <GetInTouch />
            <Footer />
        </div>
    )
}
