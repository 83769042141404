import ArtBuildingHeader, { ArtVirtualTour } from "../../components/ArtComponents"
import { useState, useRef, useEffect } from "react";
import arrow from '../../assets/arrow.png'
import building_white from '../../assets/building.jpeg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Footer from "../../components/FooterComponents";
import Navbar from "../../components/NavbarComponents";
import { apiHandler, apiImageFilesHandler, apiImageHandler } from "../../utilities/apiHandler";
import { useParams } from "react-router-dom";

export default function ArtDetailPage() {

    const { type, id } = useParams()
    const [headerData, setHeaderData] = useState([]);
    const [files, setFiles] = useState([])
    const [listsData, setListsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headerData = await apiHandler("art_" + type + "_lists/"+id);
                headerData.image = await apiImageHandler(headerData.image);
                const files = await apiImageFilesHandler(headerData.gallery)

                const listsData = await apiHandler("art_" + type + "_lists");
                listsData.map(async (item) => {
                    item.image = await apiImageHandler(item.image);
                    return item;
                })

                setListsData(listsData)

                setFiles(files)
                setHeaderData(headerData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="max-w-[100vw] h-full flex flex-col justify-center items-center overflow-hidden">
            <Navbar type="default"/>
            <DetailOverview data={headerData} />
            <Gallery files={files} />
            <ArtVirtualTour data={listsData}/>
            <Footer />
        </div>
    )
}

function Gallery({files}) {
    const [index, setIndex] = useState(1);

    return (
        <div className="flex flex-col justify-center items-center w-[89vw] h-full mt-[5.6vw] gap-[2.9vw] border-b-[1px] border-black pb-[5.5vw]">
            <div className="w-full h-[6.3vw] flex justify-between">
                <div className="text-xl">Gallery</div>
                <div className="flex gap-[2.8vw] items-center">
                    <button className="w-[7.2vw] h-[2.8vw] flex justify-center items-center border-[1px] border-black" id="swiper-next" >
                        <img src={arrow} className="w-[2vw]"></img>
                    </button>
                    <div className="text-lg">
                        <span id="current-slide">{index}</span>/{files.length}
                    </div>
                </div>
            </div>

            {/* Slider Section */}
            <div className="w-[100vw] h-[60vw] overflow-visible ml-[11vw]">
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1.5}
                    navigation={{
                        nextEl: "#swiper-next",
                        prevEl: "#swiper-prev",
                    }}
                    modules={[Navigation]}
                    onSlideChange={(swiper) => setIndex(swiper.activeIndex + 1)}
                >
                    {files.map((item, idx) => (
                        <SwiperSlide key={idx}>
                            <img
                                src={item}
                                alt={`Art ${idx + 1}`}
                                className="w-[72.4vw] h-[60vw] object-cover"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

function DetailOverview({data}) {
    return (
        <div className="flex flex-col justify-center items-center w-[100vw] h-full mt-[7.8vw]">
            <div className="flex flex-col justify-center items-center w-[89vw] h-full gap-[2.8vw]">
                <div className="flex justify-start items-center w-full h-full text-xxl">
                    {data.title}
                </div>
                <div className="w-[89vw] h-[54.3vw] ">
                    <img className="w-full h-full object-cover" src={data.image}></img>
                </div>
            </div>
            <div className="flex justify-center w-[89vw] mt-[7.8vw] h-auto border-black border-b-[1px] pb-[5.5vw]">
                <div className="flex flex-col justify-between w-full gap-[16.8vw]">
                    <div className="flex flex-col gap-[1.7vw] ">
                        <div className="w-full h-fit text-xl">
                            Overview
                        </div>
                        <div className="w-[35vw] h-[14.6vw] text-md">
                            {data.overview}
                        </div>
                    </div>
                    <div className="w-[18.3vw] h-[3.8vw] flex justify-center items-center bg-darkGreen text-white text-md">
                        Start Virtual Tour
                    </div>
                </div>
                <div className="w-[60%] flex items-stretch">
                    <div className="w-full h-full flex items-end ">
                        <div className="w-full border-black border-l-[1px] flex justify-center items-start h-full pl-[1.7vw] pr-[4.7vw]">
                            <div className="flex flex-col items-center gap-[5.6vw] mt-[5.1vw]">
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div className="text-xl">
                                        {data.title_spec_one}
                                    </div>
                                    <div className="text-md">
                                        {data.value_spec_one}
                                    </div>
                                </div>
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div className="text-xl">
                                        {data.title_spec_two}
                                    </div>
                                    <div className="text-md">
                                        {data.value_spec_two}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full flex items-end ">
                        <div className="w-full border-black border-l-[1px] flex justify-around items-start h-[80%] pl-[1.7vw] pr-[4.7vw]">
                            <div className="flex flex-col items-center gap-[5.6vw] mt-[5.1vw]">
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div className="text-xl">
                                        {data.title_spec_three}
                                    </div>
                                    <div className="text-md">
                                        {data.value_spec_three}
                                    </div>
                                </div>
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div className="text-xl">
                                        {data.title_spec_four}
                                    </div>
                                    <div className="text-md">
                                        {data.value_spec_four}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full flex items-end ">
                        <div className="w-full border-black border-l-[1px] flex justify-around items-start h-[70%] pl-[1.7vw]">
                            <div className="flex flex-col items-center gap-[5.6vw] mt-[5.1vw]">
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div className="text-xl">
                                        {data.title_spec_five}
                                    </div>
                                    <div className="text-md">
                                        {data.value_spec_five}
                                    </div>
                                </div>
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div className="text-xl">
                                        {data.title_spec_six}
                                    </div>
                                    <div className="text-md">
                                        {data.value_spec_six}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}