import howDoWeWork from '../../assets/howDoWeWork.jpeg'

export default function Test() {
  const data = [
    {
      step: "Step 1",
      title: "SITE SURVEYS",
      desc: "Comprehensive arrangements for your visit from overseas, including airport transfers, accommodation, and site surveys.",
      content: "We provide a comprehensive, hassle-free arrangement  for your visit from overseas, ensuring a smooth and enjoyable experience from start to finish. From personalized airport transfers and premium accommodation to guided site surveys, we take care of  every detail."
    },
    {
      step: "Step 2",
      title: "SITE SURVEYS",
      desc: "Comprehensive arrangements for your visit from overseas, including airport transfers, accommodation, and site surveys.",
      content: "We provide a comprehensive, hassle-free arrangement  for your visit from overseas, ensuring a smooth and enjoyable experience from start to finish. From personalized airport transfers and premium accommodation to guided site surveys, we take care of  every detail."
    },
    {
      step: "Step 3",
      title: "SITE SURVEYS",
      desc: "Comprehensive arrangements for your visit from overseas, including airport transfers, accommodation, and site surveys.",
      content: "We provide a comprehensive, hassle-free arrangement  for your visit from overseas, ensuring a smooth and enjoyable experience from start to finish. From personalized airport transfers and premium accommodation to guided site surveys, we take care of  every detail."
    },
    {
      step: "Step 4",
      title: "SITE SURVEYS",
      desc: "Comprehensive arrangements for your visit from overseas, including airport transfers, accommodation, and site surveys.",
      content: "We provide a comprehensive, hassle-free arrangement  for your visit from overseas, ensuring a smooth and enjoyable experience from start to finish. From personalized airport transfers and premium accommodation to guided site surveys, we take care of  every detail."
    },
    {
      step: "Step 5",
      title: "SITE SURVEYS",
      desc: "Comprehensive arrangements for your visit from overseas, including airport transfers, accommodation, and site surveys.",
      content: "We provide a comprehensive, hassle-free arrangement  for your visit from overseas, ensuring a smooth and enjoyable experience from start to finish. From personalized airport transfers and premium accommodation to guided site surveys, we take care of  every detail."
    }
  ]
  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center p-[5.5vw] gap-[5.5vw]">
      <div className="w-full h-full flex justify-start items-center">
        <div className="text-[1.6vw]">HOW DO WE WORK</div>
      </div>
      <div className="w-full h-full flex flex-col justify-center items-center ">
        {
          data.map((data, index) => (
            <HowDoWeWorkCard data={data} key={index} />
          ))
        }
      </div>
    </div>
  );
}

function HowDoWeWorkCard({ data }) {
  return (
    <div className="w-full h-full flex justify-between items-center gap-[2.7vw] py-[2.7vw] border-lightGray border-b-[1px]">
      <div className='w-[34.9vw] h-[25vw]'>
        <img src={howDoWeWork} className='w-full h-full object-cover'></img>
      </div>
      <div className='w-[51.7vw] h-full flex flex-col justify-center items-start gap-[2.7vw]'>
        <div className='w-full h-full flex flex-col justify-center items-start font-light'>
          <div className='text-[1.1vw]'>
            {data.step}
          </div>
          <div className='text-[2.7vw]'>
            {data.title}
          </div>
        </div>
        <div className='w-full h-full flex flex-col justify-center items-start gap-[1.6vw]'>
          <div className='text-[1.3vw]'>
            {data.desc}
          </div>
          <div className='text-[1.1vw] font-light'>
            {data.content}
          </div>
        </div>
      </div>
    </div>
  )
}
