import axios from 'axios';

async function apiHandler(url) {
    try {
        const response = await axios.get('https://admin.opatan.id/items/' + url);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiImageHandler(id) {
    try {
        return 'https://admin.opatan.id/assets/' + id
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiImageFilesHandler(data) {
    data.images = []
    try {
        // Gunakan Promise.all untuk menunggu semua permintaan async selesai
        const results = await Promise.all(
            data.map(async (item) => {
                const result = await axios.get(
                    'https://admin.opatan.id/items/art_interior_detail_header_files/' + item
                );
                return await apiImageHandler(result.data.data.directus_files_id);
            })
        );

        return results
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}


export { apiHandler, apiImageHandler, apiImageFilesHandler };
