import Navbar from "../components/NavbarComponents";
import { Swiper, SwiperSlide } from "swiper/react";
import howDoWeWork from "../assets/howDoWeWork.jpeg";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { motion, useScroll, useTransform } from "framer-motion";
import Marquee from "react-fast-marquee";
import { ArtDetail, GetInTouch } from "../components/ArtComponents";
import Footer from "../components/FooterComponents";
import { apiHandler, apiImageHandler } from "../utilities/apiHandler";
import { scroll } from "motion";
import arrow from "../assets/arrow.png";
import arrowTopRightWhite from "../assets/arrowTopRightWhite.png";
import arrowTopRightBlack from "../assets/arrowTopRightBlack.png";
import arrowRightWhite from "../assets/arrowRightWhite.png";
import building from "../assets/building.jpeg";
import { useInView } from "react-intersection-observer";

export default function Home() {
  const [artDetailData, setArtDetailData] = useState([]);
  const [howDoWeWorkData, setHowDoWeWork] = useState([]);
  const [animation, setAnimation] = useState(true)
  const [videoData, setVideoData] = useState({});
  const [ourCollectionTopData, setOurCollectionTopData] = useState([]);
  const [ourCollectionBottomData, setOurCollectionBottomData] = useState([]);
  const [whoIsOpatanMadeForData, setWhoisOpatanMadeForData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerData = await apiHandler("opatan_do");
        headerData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const howDoWeWorkData = await apiHandler("how_do_we_work");
        howDoWeWorkData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const ourCollectionTopData = await apiHandler("collections");
        ourCollectionTopData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const ourCollectionBottomData = await apiHandler("collection_bottom");
        ourCollectionBottomData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const videoData = await apiHandler("trusted_video");
        videoData[0].image_slider = await apiImageHandler(
          videoData[0].image_slider
        );

        const whoIsOpatanMadeForData = await apiHandler("who_is_opatan_made_for");
        whoIsOpatanMadeForData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        setWhoisOpatanMadeForData(whoIsOpatanMadeForData);
        setVideoData(videoData[0]);
        setOurCollectionBottomData(ourCollectionBottomData);
        setOurCollectionTopData(ourCollectionTopData);
        setHowDoWeWork(howDoWeWorkData);
        setArtDetailData(headerData);

        const timer = setTimeout(() => {
          setAnimation(false);
        }, 5000);

        return () => clearTimeout(timer);


      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="max-w-[100vw] h-full overflow-hidden">
      <Preloader />
      <Navbar type="default" />
       <HomeCard /> 
      <TrustedBy />
      <Description video={videoData} />
      <ArtDetail data={artDetailData} />
      <OurCollections
        dataTop={ourCollectionTopData}
        dataBottom={ourCollectionBottomData}
      />
      <WhoIsOpatanMadeFor data={whoIsOpatanMadeForData} />
      <HowDoWeWorkSection data={howDoWeWorkData} />
      <GetInTouch />
      <Footer />
      <h1 className="font-bold underline">Home</h1>
      <a href="https://opatan.id/contact">Contact</a>
      <a href="https://opatan.id/real-estate">real-estate</a>
      <a href="https://opatan.id/detail/building">Building detail</a>
      <a href="https://opatan.id/about-us">About us</a>
      <a href="https://opatan.id/faq">faq</a>
      <a href="https://opatan.id/art/art">Art type</a>
    </div>
  );
}

function Preloader() {
  const [isAnimating, setIsAnimating] = useState(true); // State untuk memantau animasi

  // Menambahkan dan menghapus overflow: hidden pada body saat animasi dimulai dan selesai
  useEffect(() => {
    if (isAnimating) {
      document.body.style.overflow = "hidden"; // Menonaktifkan scroll saat animasi berlangsung
    } else {
      document.body.style.overflow = "auto"; // Mengaktifkan scroll setelah animasi selesai
    }

    return () => {
      document.body.style.overflow = "auto"; // Mengaktifkan scroll jika komponen di-unmount
    };
  }, [isAnimating]);

  if (isAnimating) {
    return (
      <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-30 flex justify-center items-center">
        {/* Bg White dengan animasi opacity */}
        <motion.div
          className="w-[100vw] h-[100vh] absolute top-0 left-0 z-10 overflow-hidden flex justify-center items-center bg-white"
          initial={{ opacity: 1 }}  // Awal opacity 1
          animate={{
            opacity: [1, 1, 1, 0],  // Tetap 1 selama detik 1-3, lalu jadi 0 pada detik 4
          }}
          transition={{
            duration: 2.3,  // Durasi total animasi 4 detik
            times: [0, 0.75, 1, 1],  // 1-3 detik opacity 1, 4 detik opacity 0
            ease: "easeInOut",  // Jenis easing
            // Mengubah status animasi setelah selesai
          }}
        >
        </motion.div>

        {/* Animasi pergerakan untuk bg-red-300 */}
        <motion.div
          className="w-[25vw] h-full bg-darkGreen relative z-20"  // z-20 agar tetap di atas
          initial={{ top: "100vh", opacity: 1 }}
          animate={{ top: ["100vh", "0vh", "0vh", "-100vh"], opacity: 1 }}
          transition={{
            duration: 3,
            times: [0, 0.3, 0.7, 1],
            ease: "easeInOut",
            delay: 0,
          }}
        ></motion.div>

        <motion.div
          className="w-[25vw] h-full bg-darkGreen relative z-20"
          initial={{ top: "100vh" }}
          animate={{ top: ["100vh", "0vh", "0vh", "-100vh"] }}
          transition={{
            duration: 3,
            times: [0, 0.3, 0.7, 1],
            ease: "easeInOut",
            delay: 0.5,
          }}
        ></motion.div>

        <motion.div
          className="w-[25vw] h-full bg-darkGreen relative z-20"
          initial={{ top: "100vh" }}
          animate={{ top: ["100vh", "0vh", "0vh", "-100vh"] }}
          transition={{
            duration: 3,
            times: [0, 0.3, 0.7, 1],
            ease: "easeInOut",
            delay: 1,
          }}
        ></motion.div>

        <motion.div
          className="w-[25vw] h-full bg-darkGreen relative z-20"
          initial={{ top: "100vh" }}
          animate={{ top: ["100vh", "0vh", "0vh", "-100vh"] }}
          transition={{
            duration: 3,
            times: [0, 0.3, 0.7, 1],
            ease: "easeInOut",
            delay: 1.5,
            onComplete: () => setIsAnimating(false)
          }}
        ></motion.div>
      </div>
    )
  }
}

function WhoIsOpatanMadeFor({ data }) {
  const [index, setIndex] = useState(1);
  const swiperRef = useRef(null);

  return (
    <div className="w-full h-full flex justify-center items-center pt-[5.5vw] flex-col">
      <div className="w-full h-full justify-between items-start flex mb-[2.7vw] px-[5.5vw]">
        <div className="text-md">WHO IS OPATAN MADE FOR</div>
        <div className="flex justify-center items-center gap-[5.8vw]">
          <img
            src={arrow}
            className="w-[1.6vw] h-[1.11vw]"
            id="swiper-next"
            onClick={() => {
              const swiper = swiperRef.current;
              if (swiper && index < data.length) {
                swiper.slideNext(); // Navigasi ke slide berikutnya
              }
            }}
            disabled={index >= data.length}
          ></img>
          <div className="text-md3">
            {index}/{data.length}
          </div>
        </div>
      </div>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={false} // Nonaktifkan navigasi default Swiper
        modules={[Navigation]}
        spaceBetween={window.innerWidth * 0.016}
        slidesOffsetBefore={0}
        slidesPerView={2.44}
        slidesPerGroup={1}
        initialSlide={0}
        onSlideChange={(swiper) => {
          // Update index, tetapi batasi ke jumlah data
          setIndex(Math.min(swiper.activeIndex + 1, data.length));
        }}
        className="w-full !h-[47.6vw] flex !ml-[5.5vw] "
      >
        {data.map((item, idx) => (
          <SwiperSlide
            key={idx}
            className="basis-[40%] w-[40.2vw] h-[37.7vw] flex justify-center items-center transition-all duration-300 ease-in-out relative group"
          >
            <div className="w-full h-[37.7vw] group-hover:h-[47.6vw] transition-all duration-500 ease-in-out justify-start relative">
              <div className="w-full h-full relative z-0">
                <img
                  src={item.image}
                  alt={`Art ${idx + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-fit h-fit top-[32.3vw] absolute ml-[1.6vw] text-md flex gap-[1.3vw] justify-start items-center text-white">
                <div>{item.title}</div>
                <img src={arrowRightWhite} className="w-[1.6vw] h-[1.5vw]" />
              </div>
              {/* hover effect */}
              <div className="w-full h-[23.8vw] absolute bottom-0 z-10 bg-darkGreen2 text-white px-[1.6vw] opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                <div className="w-full flex flex-col justify-center items-start mt-[5.9vw] gap-[1.3vw]">
                  <div className="w-full h-full flex justify-start items-center gap-[1.9vw]">
                    <div className="text-md3">{item.title}</div>
                    <img
                      src={arrowTopRightWhite}
                      className="w-[1.6vw] h-[1.5vw]"
                    />
                  </div>
                  <div className="text-sm">
                    {item.detail}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* Tambahkan slide bayangan */}
        <SwiperSlide className="basis-[40%] w-[40.2vw]"></SwiperSlide>
        <SwiperSlide className="basis-[40%] w-[40.2vw]"></SwiperSlide>
        <SwiperSlide className="basis-[40%] w-[40.2vw]"></SwiperSlide>
      </Swiper>
    </div>
  );
}

function Video({ data }) {
  return (
    <div className="w-full h-[71.6vw]">
      {data.image_slider ? (
        <video
          src={data.image_slider}
          className="w-full h-full"
          autoPlay
          muted
        ></video>
      ) : (
        <div className="w-full h-full flex items-center justify-center bg-gray-200 text-gray-500">
          <p></p>
        </div>
      )}
    </div>
  );
}

function OurCollections({ dataTop, dataBottom }) {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center mt-[11.1vw]">
      <div className="w-full h-[35.8vw]">
        <Marquee className="w-full h-[35.8vw] flex">
          {dataTop.map((item, idx) =>
            idx % 2 == 0 ? (
              <div
                key={idx}
                className="w-[32vw] h-[35.8vw] mx-[3.5vw] flex items-start"
              >
                <div className="relative group">
                  <img
                    src={item.image}
                    className="max-w-[32vw] max-h-[35.8vw] object-contain"
                    alt="image"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-lightGray bg-opacity-50 flex justify-center items-center text-[3.3vw] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.title}
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={idx}
                className="w-[32vw] h-[35.8vw] mx-[5vw] flex items-end"
              >
                <div className="relative group">
                  <img
                    src={item.image}
                    className="max-w-[32vw] max-h-[35.8vw] object-contain"
                    alt="image"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-lightGray bg-opacity-50 flex justify-center items-center text-[3.3vw] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.title}
                  </div>
                </div>
              </div>
            )
          )}
        </Marquee>
      </div>
      <div className="text-xxxl w-full h-full mt-[5.8vw] mb-[3.49vw] ml-[5.5vw]">
        Our Collections
      </div>
      <div className="w-full h-[35.8vw]">
        <Marquee className="w-full h-[35.8vw] flex">
          {dataBottom.map((item, idx) =>
            idx % 2 == 0 ? (
              <div
                key={idx}
                className="w-[32vw] h-[35.8vw] mx-[5vw] flex items-start"
              >
                <div className="relative group">
                  <img
                    src={item.image}
                    className="max-w-[32vw] max-h-[35.8vw] object-contain"
                    alt="image"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-lightGray bg-opacity-50 flex justify-center items-center text-[3.3vw] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.title}
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={idx}
                className="w-[32vw] h-[35.8vw] mx-[5vw] flex items-end"
              >
                <div className="relative group">
                  <img
                    src={item.image}
                    className="max-w-[32vw] max-h-[35.8vw] object-contain"
                    alt="image"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-lightGray bg-opacity-50 flex justify-center items-center text-[3.3vw] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.title}
                  </div>
                </div>
              </div>
            )
          )}
        </Marquee>
      </div>
    </div>
  );
}

function Description({ video }) {
  const { scrollYProgress } = useScroll();
  const [show, setShow] = useState(false)
  const [direction, setDirection] = useState(0)
  const [lastPosition, setLastPosition] = useState('')

  const { inView, ref } = useInView({
    triggerOnce: false, // Trigger more than once
    threshold: 1, // Element needs to be 50% visible to trigger inView
  });

  const translateXLeft = useTransform(
    scrollYProgress,
    [0.1, 0.21],
    ["-34.9vw", "0vw"]
  );
  const translateXRight = useTransform(
    scrollYProgress,
    [0.1, 0.21],
    ["34.9vw", "0vw"]
  );

  useEffect(() => {
    const unsubscribe = translateXLeft.on("change", (value) => {
      setLastPosition(value)
    });

    return () => unsubscribe();  // Cleanup listener saat komponen unmount
  }, [translateXLeft]);

  useEffect(() => {

    let timer = setTimeout(() => setShow(true), 500);
    const _onScroll = e => {
      if (!show) return
      setShow(false)
      if (e.deltaY < 0) {
        setDirection(0)
      }
      if (e.deltaY > 0) {
        setDirection(1)
      }
    }
    window.addEventListener("wheel", _onScroll);

    return () => {
      clearTimeout(timer)
      window.removeEventListener("wheel", _onScroll)
    };
  }, [direction, show]);


  return (
    <div
      className="max-w-[100vw] h-full flex flex-col justify-center items-center gap-[16.6vw]  relative pb-[16vw]"
    >
      <div className="w-full h-full flex flex-col justify-center items-start text-[8.39vw] text-darkGreen ml-[2.2vw] ">
        <motion.div style={{ x: translateXLeft }} className="text-[8.39vw]">
          YOUR VILLA
        </motion.div>

        <motion.div className="text-[8.39vw] relative">
          <>
            {direction == 1 && lastPosition == "0vw" ? (
              <motion.div
                className="absolute bg-red-200 z-20"
                initial={{ width: "29.3vw", height: "11.3vw", top: "0vw", left: "0vw" }}
                animate={{ width: "99.1vw", height: "60vw", top: "-20vw", left: "-1.1vw" }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
              >
                <video
                  src={video.image_slider}
                  className="w-full h-full object-cover"
                  autoPlay
                  muted
                ></video>
              </motion.div>
            ) : direction == 0 && lastPosition !== "0vw" ? (
              <motion.div
                className="bg-red-200 absolute z-20"
                initial={{ width: "99.1vw", height: "60vw", top: "-20vw", left: "-1.1vw" }}
                animate={{ width: "29.3vw", height: "11.3vw", top: "0vw", left: "0vw" }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
              >
                <video
                  src={video.image_slider}
                  className="w-full h-full object-cover absolute"
                  autoPlay
                  muted
                ></video>
              </motion.div>
            ) : (
              <motion.div
                className="bg-red-200 absolute z-20"
                initial={{ width: "29.3vw", height: "11.3vw", top: "0vw", left: "0vw" }}
                animate={{ width: "29.3vw", height: "11.3vw", top: "0vw", left: "0vw" }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
              >
                <video
                  src={video.image_slider}
                  className="w-full h-full object-cover absolute"
                  autoPlay
                  muted
                ></video>
              </motion.div>
            )}
          </>
          <div className="ml-[32.3vw]">REIMAGINED AS</div>
        </motion.div>
        <motion.div style={{ x: translateXRight }} className="text-[8.39vw]">
          A WORK OF ART
        </motion.div>
      </div>
    </div>
  );
}

function HowDoWeWorkSection({ data }) {
  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center p-[5.5vw] gap-[5.5vw]">
      <div className="w-full h-full flex justify-start items-center">
        <div className="text-[1.6vw]">HOW DO WE WORK</div>
      </div>
      <div className="w-full h-full flex flex-col justify-center items-center ">
        {data.map((data, index) => (
          <HowDoWeWorkCard data={data} key={index} />
        ))}
      </div>
    </div>
  );
}

function HowDoWeWorkCard({ data }) {
  return (
    <div className="w-full h-full flex justify-between items-center gap-[2.7vw] py-[2.7vw] border-lightGray border-b-[1px]">
      <div className="w-[34.9vw] h-[25vw]">
        <img src={data.image} className="w-full h-full object-cover"></img>
      </div>
      <div className="w-[51.7vw] h-full flex flex-col justify-center items-start gap-[2.7vw]">
        <div className="w-full h-full flex flex-col justify-center items-start font-light">
          <div className="text-[1.1vw]">Step {data.id}</div>
          <div className="text-[2.7vw]">{data.title}</div>
        </div>
        <div className="w-full h-full flex flex-col justify-center items-start gap-[1.6vw]">
          <div className="text-[1.3vw]">{data.subtitle}</div>
          <div className="text-[1.1vw] font-light">{data.detail}</div>
        </div>
      </div>
    </div>
  );
}

function HomeCard() {
  const [headerData, setHeaderData] = useState({});
  const [sliderData, setSliderData] = useState([]);


  const { scrollYProgress } = useScroll();
  const [show, setShow] = useState(false)
  const [direction, setDirection] = useState(0)
  const [lastPosition, setLastPosition] = useState(0)

  const { inView, ref } = useInView({
    triggerOnce: false, // Trigger more than once
    threshold: 0.89, // Element needs to be 50% visible to trigger inView
  });


  useEffect(() => {
    const unsubscribe = scrollYProgress.on("change", (value) => {
      console.log(value)
      if(inView){
        console.log("last position: " + value)
        setLastPosition(value)
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, [scrollYProgress, inView]);

  useEffect(() => {

    let timer = setTimeout(() => setShow(true), 500);
    const _onScroll = e => {
      if (!show) return
      setShow(false)
      if (e.deltaY < 0) {
        setDirection(0)
      }
      if (e.deltaY > 0) {
        setDirection(1)
      }
    }
    window.addEventListener("wheel", _onScroll);

    return () => {
      clearTimeout(timer)
      window.removeEventListener("wheel", _onScroll)
    };
  }, [direction, show]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerData = await apiHandler("section_header");
        setHeaderData(headerData);

        const sliderData = await apiHandler("slider_header");

        sliderData.map(async (item) => {
          item.background = await apiImageHandler(item.background);
          return item;
        });
        console.log(sliderData)
        setSliderData(sliderData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  if (sliderData) {
    return (
      <div className="max-w-[100vw] h-[110vh] flex justify-center items-center relative " ref={ref}>
        <div className="w-[59vw] h-fit flex flex-col justify-center items-center text-darkGreen gap-[1.4vw]" >
          <div className="text-sm">
            {headerData.subtitle}
          </div>
          <div className="text-xl3 text-center">
            {headerData.title}
          </div>
          <div className="text-md flex justify-center items-center gap-[0.6vw]">
            Stay at Opatan
            <img src={arrowTopRightBlack} className="w-[0.9vw] h-[0.9vw]"></img>
          </div>
        </div>
        {
          (direction == 1 && inView) ?
            <div className="w-full h-full flex absolute top-0 ">
              {sliderData.map((data, index) => {
                const slidePosition = index % 2 === 0 ? "100vh" : "-100vh"; // Adjusting direction for slides
                const animatePosition = index % 2 === 0 ? "0vh" : "0vh"; // Animation start and end position

                return (
                  <motion.div
                    key={index}
                    className="w-[33.3vw] h-full relative z-20 flex justify-center items-center group overflow-hidden"
                    initial={{ top: slidePosition, opacity: 0 }}
                    animate={{ top: [slidePosition, animatePosition], opacity: 1 }}
                    transition={{
                      duration: 1,
                      times: [0, 1],
                      ease: "easeInOut",
                      delay: 0,
                    }}
                  >
                    <div
                      className="absolute top-0 left-0 w-full h-full !bg-black !transition-opacity !duration-1000 !opacity-0 group-hover:!opacity-50 pointer-events-none z-10"
                    ></div>
                    <motion.img
                      className="absolute top-0 left-0 object-cover w-full h-full"
                      src={data?.background || building}
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    />
                    <motion.div
                      className="z-10 text-xl text-white !opacity-0 group-hover:!opacity-100"
                      transition={{
                        duration: 0.5,
                        ease: "easeInOut",
                      }}
                    >
                      {data?.title || "Loading"}
                    </motion.div>
                  </motion.div>
                );
              })}
            </div>
            :
            <div className="w-full h-full flex absolute top-0 ">
              {sliderData.map((data, index) => {
                const slidePosition = index % 2 === 0 ? "0vh" : "0vh";
                const animatePosition = index % 2 === 0 ? "100vh" : "-100vh"; // Adjusting direction for reverse animations

                return (
                  <motion.div
                    key={index}
                    className="w-[33.3vw] h-full relative z-20 flex justify-center items-center group overflow-hidden"
                    initial={{ top: slidePosition, opacity: 1 }}
                    animate={{ top: [slidePosition, animatePosition], opacity: 0 }}
                    transition={{
                      duration: 1,
                      times: [0, 1],
                      ease: "easeInOut",
                      delay: 0,
                    }}
                  >
                    <div
                      className="absolute top-0 left-0 w-full h-full !bg-black !transition-opacity !duration-1000 !opacity-0 group-hover:!opacity-50 pointer-events-none z-10"
                    ></div>
                    <motion.img
                      className="absolute top-0 left-0 object-cover w-full h-full"
                      src={data?.background || building}
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    />
                    <motion.div
                      className="z-10 text-xl text-white !opacity-0 group-hover:!opacity-100"
                      transition={{
                        duration: 0.5,
                        ease: "easeInOut",
                      }}
                    >
                      {data?.title || "Loading"}
                    </motion.div>
                  </motion.div>
                );
              })}
            </div>
        }
      </div>
    );
  }
}

function TrustedBy() {
  const [data, setData] = useState([]);
  const [descriptionData, setDescriptionData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerData = await apiHandler("trusted_marquee");
        headerData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const descriptionData = await apiHandler("trusted_text");
        setDescriptionData(descriptionData);
        setData(headerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center  pl-[5.5vw]">
      <div className="w-full h-full flex justify-start items-center ">
        <div className="text-[1.6vw] py-[2.7vw]">Trusted by</div>
      </div>
      <div className="w-full h-[7.5vw] ">
        <Marquee className="w-full h-full ">
          <div className="w-full h-full flex justify-center items-center gap-[4.8vw]">
            {data.map((data, idx) => (
              <div className="w-[13.8vw] h-full" key={idx}>
                <img
                  src={data.image || building}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}
          </div>
        </Marquee>
      </div>
      <div className="w-full h-fit mt-[14.89vw] mb-[20.13vw]">
        <div className="w-[61vw] h-full text-[1.6vw]">
          {descriptionData.trusted_text_paragraph}
        </div>
      </div>
    </div>
  );
}
