import { useParams } from "react-router-dom";
import { ConnectWithUs } from "../../components/ContactComponents";
import Footer from "../../components/FooterComponents";
import Navbar from "../../components/NavbarComponents";
import { ImageHeader } from "../../components/AboutUsComponent";
import { useEffect, useState } from "react";
import { apiHandler, apiImageHandler } from "../../utilities/apiHandler";

export default function ContactPage() {
    const { type } = useParams()

    const [headerData, setHeaderData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headerData = await apiHandler("contact_detail");
                headerData.image = await apiImageHandler(headerData.image);

                setHeaderData(headerData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    return (
        <div className="flex flex-col justify-center items-center overflow-x-hidden overflow-y-hidden max-w-[100vw]">
            <Navbar type="default" />
            <ImageHeader data={headerData}/>
            <ConnectWithUs />
            <Footer />
        </div>
    )
}