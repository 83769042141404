import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiHandler, apiImageFilesHandler, apiImageHandler } from "../../utilities/apiHandler";
import Navbar from "../../components/NavbarComponents";
import Footer from "../../components/FooterComponents";
import { ArtVirtualTour } from "../../components/ArtComponents";

export default function CardDetailPage(){
    const { type, id } = useParams()
    const [headerData, setHeaderData] = useState([]);
    const [files, setFiles] = useState([])
    const [listsData, setListsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headerData = await apiHandler("art_" + type + "_sliders/" + id);
                headerData.image = await apiImageHandler(headerData.image);

                const listsData = await apiHandler("art_" + type + "_lists");
                listsData.map(async (item) => {
                    item.image = await apiImageHandler(item.image);
                    return item;
                })

                setListsData(listsData)

                setFiles(files)
                setHeaderData(headerData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="max-w-[100vw] h-full flex flex-col justify-center items-center overflow-hidden">
            <Navbar type="default" />
            <CardDescription data={headerData}/>
            <ArtVirtualTour data={listsData} />
            <Footer />
        </div>
    )
}


function CardDescription({data}){
    return (
        <div className="flex flex-col justify-center items-center w-[100vw] h-full mt-[7.8vw]">
            <div className="flex flex-col justify-center items-center w-[89vw] h-full gap-[2.8vw]">
                <div className="flex justify-start items-center w-full h-full text-xxl">
                    {data.title}
                </div>
                <div className="w-[89.5vw] h-[48.8vw] ">
                    <img className="w-full h-full object-cover" src={data.image}></img>
                </div>
            </div>
            <div className="flex justify-center w-[89vw] mt-[7.8vw] h-auto border-black border-b-[1px] pb-[5.5vw]">
                <div className="flex flex-col justify-between w-full gap-[16.8vw]">
                    <div className="flex flex-col gap-[1.7vw] ">
                        <div className="w-[35vw] h-[14.6vw] text-md">
                            {data.detail}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}